<template>
  <div>
    <h1>Welcome电商后台管理系统</h1>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
h1 {
  text-align: center;
  font-size: 35px;
}
</style>
