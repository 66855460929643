<template>
  <el-container class="home-container">
    <el-header>
      <div class="avatar_box"><img src="../assets/logo.png" alt="" @click="redirect()" /></div>
      <span>电商后台管理系统</span>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <el-container class="content">
      <el-aside :width="isCollapse ? '64px':'200px'">
        <div class="toggle-button" @click="toggleCollapse"><i :class="[isCollapse ? 'el-icon-s-unfold' :  'el-icon-s-fold']" style="font-size: 25px"></i></div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          :default-active="activePath"
          router
          :collapse-transition='false'
          :collapse=isCollapse
          unique-opened
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409fff"
        >
          <!-- 一级菜单 -->
          <el-submenu v-for="item in menuList" :key="item.id" :index="item.id +''">
            <!-- 一级菜单的模板 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{item.authName}}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
            v-for="subItem in item.children"
            :index="'/'+ subItem.path"
            :key="subItem.id"
            @click="saveNavState('/'+ subItem.path)">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-menu"></i>
                <!-- 文本 -->
                <span>{{subItem.authName}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main :class="[isCollapse ? 'main-box-65' : 'main-box-200']">
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      // 左侧菜单数据
      menuList: [],
      iconsObj: {
        125: 'el-icon-user',
        103: 'el-icon-s-check',
        101: 'el-icon-s-cooperation',
        102: 'el-icon-s-order',
        145: 'el-icon-s-platform'
      },
      // 控制菜单收起
      isCollapse: false,
      // 被激活的链接地址
      activePath: ''
    }
  },
  // 创建时渲染菜单
  created () {
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    // 重定向Welcome页面
    redirect() {
      this.activePath = ''
      this.$router.push({
        path: '/Welcome'
      })
    },
    // 退出登录
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList () {
      const { data: res } = await this.$http.get('menus')
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.menuList = res.data
    },
    // 点击按钮，切换折叠和展开菜单
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}
.avatar_box {
  box-shadow: 0 0 3px #ddd;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #eee;
  padding: 2px;
}
img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #eee;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  // padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  position:fixed;
  left:0;
  top:0;
  width:100%;
  z-index: 999;
}
.content {
  margin-top: 60px;
}
.el-aside {
  background-color: #333744;
  position: fixed;
  height: 100%;
  z-index: 999;
  .el-menu {
    border-right: none;
  }
}
.main-box-200 {
  margin-left: 200px;
}
.main-box-65 {
  margin-left: 65px;
}
.el-main {
  background-color: #eaedf1;
}
.toggle-button {
  background-color: #4a5064;
  color: white;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
